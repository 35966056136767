import { HeroSection, ContentSectionData } from 'types/domain/content';
// import TestImage from 'assets/images/_test/test1.svg';

import clientImageABC from 'assets/images/clients/abc.png';
import clientImageAccenture from 'assets/images/clients/accenture.png';
import clientImageAdobe from 'assets/images/clients/adobe.png';
import clientImageAGD from 'assets/images/clients/agd.png';
import clientImageArtsCentre from 'assets/images/clients/artscentremelb.png';
import clientImageAvanade from 'assets/images/clients/avanade.png';
import clientImageBBDO from 'assets/images/clients/bbdosf.png';
import clientImageCassette from 'assets/images/clients/cassette.png';
import clientImageCodeVis from 'assets/images/clients/codeandvisual.png';
import clientImageCSIRO from 'assets/images/clients/csiro.png';
import clientImageDDB from 'assets/images/clients/tribal.png';
import clientImageFujifilm from 'assets/images/clients/fujifilm.png';
import clientImageIE from 'assets/images/clients/ie.png';
import clientImageMinimega from 'assets/images/clients/minimega.png';
import clientImageMOHF from 'assets/images/clients/mohf.png';
import clientImageMoso from 'assets/images/clients/moso.png';
import clientImageOHM from 'assets/images/clients/ohm.png';
import clientImageSBS from 'assets/images/clients/sbs.png';
import clientImageSportsbet from 'assets/images/clients/sportsbet.png';
import clientImageSuperEpic from 'assets/images/clients/superepic.png';
import clientImageTelstra from 'assets/images/clients/telstra.png';
import clientImageToday from 'assets/images/clients/today.png';
import clientImageUniMelb from 'assets/images/clients/unimelb.png';
import clientImageZoos from 'assets/images/clients/zoosvic.png';

// Test images
import computerImage from 'assets/images/home-web-dev.png';
import mobileImage from 'assets/images/home-mobile.png';
import airImage from 'assets/images/home-air-ane.png';
import touchScreenAppsImage from 'assets/images/home-touchscreenapps.png';
import pluginsImage from 'assets/images/home-plugins.png';

// Main homepage hero content
export const heroContent: HeroSection = {
  title:
    'distriqt is a <b>digital consultancy</b>, providing development and technology services for modern businesses.',
};

// Main intro text section
export const introContent: ContentSectionData = {
  title: 'Digital development experts',
  items: [
    {
      title: '',
      paragraphs: [
        'We specialise in development services for all modern technologies for web, mobile, server, and cloud-based platforms. From interactive websites, mobile applications and back-end server systems, we provide innovative, professional and modern solutions for any digital challenge.',
        'Whatever your development needs, we can help. We offer services for direct clients, plus contracting support and consulting expertise for companies and agencies.',
      ],
    },
  ],
};

// Services intro content for homepage
export const homeServicesContent: ContentSectionData = {
  title: 'Services',
  description:
    'We provide consulting and contracting expertise and solutions in a broad range of digital technologies. With a wealth of experience in the IT industry, we offer modern sophisticated solutions for all of our clients.',
  items: [
    {
      title: 'Full-stack web development',
      paragraphs: [
        'We love the modern web, and have extensive experience building solutions using the latest technologies and best practices.',
        'We provide complete development services for websites and apps, from the front end to the back end.',
        'Utilising the best tools for the job, we implement cloud-based solutions or more traditional technologies, depending on what is most suited.',
      ],
      image: computerImage,
    },
    {
      title: 'Mobile & app development',
      paragraphs: [
        'We offer mobile app development solutions for both iOS and Android platforms.',
        'In addition to this, we also provide support, development assistance and maintenance for existing apps and products to help companies stay on top of the evolving mobile landscape.',
      ],
      image: mobileImage,
    },
    {
      title: 'Touch screen interactive apps',
      paragraphs: [
        'We also offer interactive touch screen application development for in-store and outdoor displays.',
        "We've developed touch screen apps and games for clients including Telstra, Myer and many property development companies to display engaging interactive content for users.",
      ],
      image: touchScreenAppsImage,
    },
    {
      title: 'Extension and plugin development',
      paragraphs: [
        'We specialise in making systems <strong>work together</strong>.',
        'We have many years of experience helping developers integrate native SDKs into applications using many different frameworks, including <strong>Unity, AIR, Haxe and Flutter</strong>. We also provide services to simplify the integration of different systems, such as handling multiple payment stores in your application through a single API.',
        'If there is a native feature or SDK that you need access to in your application we can help you achieve that goal quickly.',
      ],
      image: pluginsImage,
      ctas: [
        {
          href: '/native-extensions',
          label: 'Read more',
          target: '_self',
        },
      ],
    },
    {
      title: 'AIR development & Adobe migration support',
      paragraphs: [
        'With over 20 years in AIR and Flash experience, we provide AIR development and native extension solutions.',
        'While Flash is a thing of the past, AIR is still a viable solution used by millions of developers. We develop <strong>commercial and custom extensions</strong> for the AIR platform.',
        'We also provide <a href="https://medium.com/airnativeextensions/managing-flash-end-of-life-e7d08b82504c" target="_blank">migration support and development</a> for companies who rely on legacy Flash applications, in order to maintain functionality for their products and services.',
      ],
      image: airImage,
    },
    // {
    //   title: 'Partner offerings',
    //   paragraphs: ['I am a paragraph'],
    //   image: 'https://via.placeholder.com/320x380.png?text=[TBD IMAGE HERE]',
    // },
  ],
};

// ANEs intro content for homepage
export const extensionsContent: ContentSectionData = {
  title: 'Looking for our native extensions?',
  items: [
    {
      paragraphs: [
        `As the providers of the widest range of native extensions for Adobe AIR on the market, we offer over 50 fully-featured extensions available for iOS and Android platforms, plus some specialised extensions now available for Windows and macOS.`,
        `We also provide a number of specialised extensions for <strong>Haxe</strong>, <strong>Unity</strong> and <strong>Flutter</strong>.`,
        `Our team specialises in custom extension development for these platforms too. We have developed hundreds of custom native extensions to allow developers to utilise hardware device integration and business specific SDKs and APIs for mobile & desktop applications. If your business
      needs something specific, get in touch.`,
      ],
    },
  ],
  ctas: [
    {
      label: 'Read more',
      href: '/native-extensions',
      target: '_self',
    },
    {
      label: 'Contact us',
      href: '/contact',
      target: '_self',
    },
    {
      label: 'See our extensions',
      href: 'https://airnativeextensions.com',
      target: '_blank',
    },
  ],
};

// Our clients content
export const clientsContent: ContentSectionData = {
  title: 'Clients',
  items: [
    {
      paragraphs: [
        'We’ve worked with clients all over the world to deliver web, mobile and in-store solutions to hundreds of digital challenges. These are a few of our favourites.',
      ],
    },
  ],
  images: [
    {
      path: clientImageTelstra,
      altText: 'Telstra',
    },
    {
      path: clientImageSBS,
      altText: 'SBS',
    },
    {
      path: clientImageABC,
      altText: 'ABC',
    },
    {
      path: clientImageAGD,
      altText: 'Attorney Generals Department Australia',
    },

    {
      path: clientImageAdobe,
      altText: 'Adobe',
    },
    {
      path: clientImageCSIRO,
      altText: 'CSIRO Data61',
    },
    {
      path: clientImageAccenture,
      altText: 'Accenture',
    },
    {
      path: clientImageAvanade,
      altText: 'Avanade',
    },
    {
      path: clientImageFujifilm,
      altText: 'Fujifilm',
    },

    {
      path: clientImageUniMelb,
      altText: 'University of Melbourne',
    },
    {
      path: clientImageBBDO,
      altText: 'BBDO San Francisco',
    },
    {
      path: clientImageZoos,
      altText: 'Zoos Victoria',
    },
    {
      path: clientImageArtsCentre,
      altText: 'Arts Centre Melbourne',
    },
    {
      path: clientImageMOHF,
      altText: 'Members Own Health Funds',
    },

    {
      path: clientImageDDB,
      altText: 'Tribal DDB',
    },
    {
      path: clientImageSportsbet,
      altText: 'Sportsbet',
    },
    {
      path: clientImageToday,
      altText: 'Today Design',
    },
    {
      path: clientImageMinimega,
      altText: 'Minimega',
    },

    {
      path: clientImageIE,
      altText: 'IE Agency',
    },
    {
      path: clientImageOHM,
      altText: 'Open House Melbourne',
    },
    {
      path: clientImageCassette,
      altText: 'Cassette',
    },
    {
      path: clientImageCodeVis,
      altText: 'Code and Visual',
    },
    {
      path: clientImageMoso,
      altText: 'Studio Moso',
    },
    {
      path: clientImageSuperEpic,
      altText: 'SUPEREPIC',
    },
  ],
};
